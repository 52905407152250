import axios from '@/libs/axios'

export function reqGetTaskEssayQuestions(taskId, payload) {
  return axios.get(`api/admin/tasks/${taskId}/essay-questions`, { params: payload })
}
export function reqGetTaskEssayQuestion(id) {
  return axios.get(`api/admin/essay-questions/${id}`)
}
export function reqCreateTaskEssayQuestion(taskId, payload) {
  return axios.post(`api/admin/tasks/${taskId}/essay-questions`, payload)
}
export function reqUpdateTaskEssayQuestion(id, payload) {
  return axios.post(`api/admin/essay-questions/${id}?_method=PATCH`, payload)
}
export function reqDeleteTaskEssayQuestion(id) {
  return axios.delete(`api/admin/essay-questions/${id}`)
}
